// frontend/src/App.js
import './App.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DayMenu from './DayMenu';
import ClassList from './ClassList';

function App() {
  const [selectedDay, setSelectedDay] = useState(new Date().toISOString().split('T')[0]);
  const [classes, setClasses] = useState([]);

  const handleSelectDay = (day) => {
    console.log('Selected date:', day);
    setSelectedDay(day);
  };


  getPaalPriceDextools();

  return (
    <div>
      <DayMenu selectedDay={selectedDay} onSelectDay={handleSelectDay} />
      <ClassList classes={classes} />
    </div>
  );
}


async function getPaalPriceDextools() {

  const endpoint = 'https://stmember.styd.cn/v1/appointment/team_course_list?shop_id=1876778621142563&date=2024-03-30&page=1&size=1000'; 

  const headers = {
      'theme-compatible': 1,
      'brand-code': 'lRyLQeaXaxp',
      'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/107.0.0.0 Safari/537.36 MicroMessenger/7.0.20.1781(0x6700143B) NetType/WIFI MiniProgramEnv/Windows WindowsWechat/WMPF WindowsWechat(0x6309092b) XWEB/8555',
      'xweb_xhr': 1,
      'app-id': 'mina',
      'mina-version': 'independent',
      'shop-id': '1876778621142563',
      'Referer': 'https://servicewechat.com/wx10c6bedd2a830f3f/11/page-frame.html'
    };

  try {
      const response = await fetch(endpoint, { headers });
      const data = await response.json();
      
      console.log(data);
      
  } catch (error) {
      console.error('Error reading the JSON file:', error);
  }
}



export default App;
