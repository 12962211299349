// src/ClassList.js
import React from 'react';

const ClassList = ({ classes }) => {
    return (
        <div>
            {classes.map((danceClass, index) => (
                <div key={index}>
                    {/* Display class details here */}
                </div>
            ))}
        </div>
    );
};

export default ClassList;
