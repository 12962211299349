// src/DayMenu.js
import React from 'react';

const DayMenu = ({ selectedDay, onSelectDay }) => {
    const generateDates = () => {
        const dates = [];
        const today = new Date();
        today.setMinutes(today.getMinutes() - today.getTimezoneOffset()); // Adjust for timezone offset
    
        for (let i = 0; i < 7; i++) {
            const futureDate = new Date(today);
            futureDate.setDate(futureDate.getDate() + i);
            dates.push(futureDate.toISOString().split('T')[0]);
        }
        return dates;
    };
    

    const dates = generateDates();

    return (
        <div className="day-menu">
            {dates.map(date => (
                <button 
                    key={date} 
                    className={`day-button ${date === selectedDay ? 'selected' : ''}`}
                    onClick={() => onSelectDay(date)}
                >
                    {date}
                </button>
            ))}
        </div>
    );
};

export default DayMenu;
